<template>
  <div class="Collections">
    <div class="head_img">
      <div class="img_title">
        <div class="img">
          <img :src="chamberinfo.chamberCommerceLogo" alt="" />
        </div>
        <div class="title">
          <p class="merchant_name">{{ chamberinfo.chamberCommerceName }}</p>
          <p>{{ chamberinfo.province }}-{{ chamberinfo.city }}</p>
        </div>
      </div>
    </div>
    <div class="switch_tab">
      <div class="store_table">
        <div
          class="store_table_item"
          v-for="(item, ii) in orderType"
          :key="ii"
          @click="storeClick(item.id)"
          :class="item.id === typeIndex ? 'active' : ''"
        >
          {{ item.code }}
        </div>
      </div>
      <div v-if="typeIndex == 1">
        <div class="label_management">
          <div class="input-with">
            <el-input
              placeholder="请输入人物名称"
              v-model="queryInfo.realName"
              class="input-with-select"
            >
              <el-button class="seach" slot="append" @click="search()">搜索</el-button>
            </el-input>
            <el-button type="primary" @click="userClick">新增人员</el-button>
          </div>
          <el-table
            :data="listData"
            stripe
            style="width: 100%"
            class="in_table"
            :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
            >:reserve-selection="true" />
            <el-table-column label="编号" align="center" type="index">
              <template slot-scope="scope">
                <span>{{ (queryInfo.pageNum - 1) * queryInfo.pageSize + scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column label="头像" align="center">
              <template v-slot="{ row }">
                <img :src="row.headImgUrl" width="42" height="42" alt="" />
              </template>
            </el-table-column>
            <el-table-column label="姓名" prop="realName" align="center"></el-table-column>
            <el-table-column
              label="商会职务"
              prop="chamberCommerceJob"
              align="center"
            ></el-table-column>

            <el-table-column label="手机号" prop="phone" align="center"></el-table-column>

            <el-table-column label="企业名称" prop="companyName" align="center"></el-table-column>

            <el-table-column label="企业职务" prop="companyJob" align="center"></el-table-column>
            <el-table-column label="操作人" prop="userName" align="center"></el-table-column>
            <el-table-column label="操作时间" prop="createTime" align="center"></el-table-column>

            <el-table-column
              label="操作"
              align="center"
              class-name="small-padding fixed-width"
              width="260px"
            >
              <template slot-scope="scope">
                <el-button style="color: #146aff" type="text" @click="editrecord(scope.row)"
                  >编辑
                </el-button>
                <el-button style="color: #fd5469" type="text" @click="delrecord(scope.row)"
                  >删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="new_page">
            <el-pagination
              :current-page="queryInfo.pageNum"
              :page-sizes="[10, 30, 50]"
              :page-size="queryInfo.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
          </div>
        </div>
      </div>
      <div v-if="typeIndex == 2">
        <div class="label_management">
          <div class="input-with">
            <el-input
              placeholder="请输入企业名称"
              v-model="queryInfo.companyName"
              class="input-with-select"
            >
              <el-button class="seach" slot="append" @click="search()">搜索</el-button>
            </el-input>
            <el-button type="primary" @click="companyClick">新增企业</el-button>
          </div>
          <el-table
            :data="listData"
            stripe
            style="width: 100%"
            class="in_table"
            :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
            >:reserve-selection="true" />
            <el-table-column label="编号" align="center" type="index">
              <template slot-scope="scope">
                <span>{{ (queryInfo.pageNum - 1) * queryInfo.pageSize + scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column label="企业logo" align="center">
              <template v-slot="{ row }">
                <img v-if="row.companyLogo" :src="row.companyLogo"  width="42" height="42" alt="" />
                <img v-else src="../../../assets/image/Group 3237.png"  width="42" height="42" alt="" />
              </template>
            </el-table-column>
            <el-table-column
              label="公司名称"
              prop="companyFullName"
              align="center"
            ></el-table-column>
            <el-table-column label="企业负责人" prop="legalPerson" align="center"></el-table-column>

            <el-table-column
              label="负责人联系电话"
              prop="companyPhone"
              align="center"
            ></el-table-column>

            <el-table-column
              label="关联秘书长"
              prop="secretaryGeneralName"
              align="center"
            ></el-table-column>

            <el-table-column label="秘书长联系电话" prop="phone" align="center"></el-table-column>
            <el-table-column label="是否有展厅" prop="" align="center">
              <template v-slot="{ row }">
                <span v-if="row.exhibitionHall == 1">有</span>
                <span v-if="row.exhibitionHall == 0">没有</span>
              </template>
            </el-table-column>
            <el-table-column label="操作人" prop="userName" align="center"></el-table-column>
            <el-table-column label="操作时间" prop="updateTime" align="center"></el-table-column>

            <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
              <template slot-scope="scope">
                <el-button style="color: #146aff" type="text" @click="editcompany(scope.row)"
                  >编辑
                </el-button>
                <el-button style="color: #fd5469" type="text" @click="delcompany(scope.row)"
                  >删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="new_page">
            <el-pagination
              :current-page="queryInfo.pageNum"
              :page-sizes="[10, 30, 50]"
              :page-size="queryInfo.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
          </div>
        </div>
      </div>
      <div v-if="typeIndex == 3">
        <div class="label_management">
          <div class="input-with">
            <el-input
              placeholder="请输入单位名称"
              v-model="queryInfo.companyName"
              class="input-with-select"
            >
              <el-button class="seach" slot="append" @click="search()">搜索</el-button>
            </el-input>
            <el-button type="primary" @click="addguidances">新增单位</el-button>
          </div>
          <el-table
            :data="listData"
            stripe
            style="width: 100%"
            class="in_table"
            :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
            >:reserve-selection="true" />
            <el-table-column label="编号" align="center" type="index">
              <template slot-scope="scope">
                <span>{{ (queryInfo.pageNum - 1) * queryInfo.pageSize + scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column label="单位名称" align="center">
              <template v-slot="{ row }">
                <span>{{ row.guidanceName }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作人" prop="legalPerson" align="center"></el-table-column>
            <el-table-column label="操作时间" prop="updateTime" align="center"></el-table-column>

            <el-table-column
              label="操作"
              align="center"
              class-name="small-padding fixed-width"
              width="260px"
            >
              <template slot-scope="scope">
                <el-button style="color: #146aff" type="text" @click="editguidance(scope.row)"
                  >编辑
                </el-button>
                <el-button style="color: #fd5469" type="text" @click="delguidanced(scope.row)"
                  >删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="new_page">
            <el-pagination
              :current-page="queryInfo.pageNum"
              :page-sizes="[10, 30, 50]"
              :page-size="queryInfo.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="新增人员" :visible.sync="open" width="700px" append-to-body>
      <el-form ref="form" :model="adduser" :rules="prules" label-width="90px">
        <el-form-item label="头像:" prop="headImgUrl">
          <div class="com_upload">
            <img-big-upload
              :imageShow.sync="adduser.headImgUrl"
              :addTitleShow="false"
              @removeImg="removeImgDian"
              @uploadOneImgShow="uploadOneImgShowDian"
            />
          </div>
          <div class="img_tips">
            <span>(建议整体尺寸为630*510px，图片格式应为jpg、jpeg、png，图片应小于12MB)</span>
          </div>
        </el-form-item>
        <el-form-item label="姓名:" prop="realName">
          <el-input v-model="adduser.realName" placeholder="请输入姓名" />
        </el-form-item>
        <el-form-item label="商会职务:" prop="chamberCommerceJob">
          <el-select v-model="adduser.chamberCommerceJob" placeholder="请选择">
            <el-option
              v-for="(item, index) in buildlist"
              :key="index"
              :label="item.val"
              :value="item.val"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="手机号:" prop="phone">
          <el-input v-model="adduser.phone" placeholder="请输入手机号" />
        </el-form-item>
        <el-form-item label="企业名称:" prop="companyName">
          <el-input v-model="adduser.companyName" placeholder="请输入企业名称" />
        </el-form-item>
        <el-form-item label="企业职务:" prop="">
          <el-input v-model="adduser.companyJob" placeholder="请输入企业职务" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submituser">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
    <el-dialog title="新增关联指导单位" :visible.sync="flag" width="500px" append-to-body>
      <el-form ref="form" :model="addguidance" label-width="90px">
        <el-form-item label="指导单位:" prop="">
          <el-input v-model="addguidance.guidanceName" placeholder="请输入指导单位" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitguidance">确 定</el-button>
        <el-button @click="cancelguidance">取 消</el-button>
      </div>
    </el-dialog>
    <el-dialog title="新增企业" :visible.sync="comflag" width="700px" append-to-body>
      <el-form ref="form" :model="addcompany" label-width="90px">
        <el-form-item label="公司名称:">
          <el-select v-model="addcompany.companyId" filterable placeholder="请选择">
            <el-option
              v-for="(item, index) in companylist"
              :key="index"
              :label="item.companyFullName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitcompany">确 定</el-button>
        <el-button @click="cancelcompany">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getcommercet,
  getchamberlist,
  getchamberuser,
  getguidance,
  adduser,
  edituser,
  getCommerceUser,
  delCommerceUser,
  addguidance,
  editguidance,
  getguidanceinfo,
  delguidance,
  addCommerceCompan,
  editCommerceCompan,
  delcompany
} from '@/api/chambercenter'
import { getCompanyList } from '@/api/demand'
export default {
  name: 'Collections',
  computed: {
    id() {
      return this.$route.query.id * 1
    }
  },
  data() {
    return {
      active: 2,
      //tab切换
      typeIndex: this.$route.query.flag * 1,
      form: {
        commodityCoverPicture: '',
        num: 1
      }, //表单收集
      queryInfo: {
        pageNum: 1,
        pageSize: 10,
        chamberCommerceId: this.$route.query.id * 1,
        companyName: null,
        realName: null
      },
      list: [
        {
          general: ''
        }
      ],
      orderType: [
        {
          id: 1,
          code: '关联人员'
        },
        {
          id: 2,
          code: '关联企业'
        },
        {
          id: 3,
          code: '关联指导单位'
        }
      ],
      prules: {
        phone: [
          { required: true, message: '请输入联系电话', trigger: 'blur' },
          // 这个只能验证手机号
          // { pattern:/^0{0,1}(13[0-9]|15[7-9]|153|156|18[7-9])[0-9]{8}$/, message: "请输入合法手机号", trigger: "blur" }
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: '请输入合法手机号/电话号',
            trigger: 'blur'
          }
        ],
        chamberCommerceJob: [{ required: true, message: '请选择商会职务', trigger: 'change' }],
        headImgUrl: [{ required: true, message: '请上传图片', trigger: 'blur' }],
        realName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        companyName: [{ required: true, message: '请输入企业名称', trigger: 'blur' }]
      },
      chamberinfo: {}, //商会详细
      listData: [], //接收信息·
      total: 0,
      adduser: {
        chamberCommerceId: this.$route.query.id * 1,
        chamberCommerceJob: null,
        companyJob: null,
        companyName: null,
        createId: null,
        createTime: null,
        headImgUrl: '',
        id: null,
        phone: null,
        realName: null,
        updateId: null,
        updateTime: null,
        wechatId: null
      }, //新增关联用户
      //新增指导单位
      addguidance: {
        chamberCommerceId: this.$route.query.id * 1,
        guidanceName: null,
        id: null
      },
      flag: false,
      addcompany: {
        companyId: null,
        chamberCommerceId: this.$route.query.id * 1
      },
      comflag: false, //新增关联企业
      companylist: [],
      buildlist: [
        {
          id: 1,
          val: '会长'
        },
        {
          id: 2,
          val: '副会长'
        },
        {
          id: 3,
          val: '秘书长'
        },
        {
          id: 4,
          val: '自定义职位'
        }
      ],
      open: false
    }
  },
  created() {
    this.search()
    this.getcommercet()
  },
  methods: {
    //获取可以新增的企业
    async getcompany() {
      const res = await getCompanyList({ companyName: '', chamberCommerceId: this.id })
      if (res.data.resultCode == 200) {
        this.companylist = res.data.data
      }
    },
    //查询商会企业信息
    async search() {
      let res
      if (this.typeIndex == 1) {
        res = await getchamberuser(this.queryInfo)
      } else if (this.typeIndex == 2) {
        res = await getchamberlist(this.queryInfo)
      } else if (this.typeIndex == 3) {
        res = await getguidance(this.queryInfo)
      }

      if (res.data.resultCode == 200) {
        this.listData = res.data.data.list
        this.total = res.data.data.total
      }
    },
    //获取商会详情
    async getcommercet() {
      const res = await getcommercet(this.id)
      if (res.data.resultCode == 200) {
        this.chamberinfo = res.data.data
      }
    },
    //tab切换
    storeClick(id) {
      this.typeIndex = id
      this.search()
    },
    //新增人员
    userClick() {
      this.open = true
    },
    //编辑关联用户
    async editrecord(row) {
      this.open = true
      const res = await getCommerceUser(row.id)
      if (res.data.resultCode == 200) {
        this.adduser = res.data.data
      }
    },
    //删除关联用户delCommerceUser
    delrecord(row) {
      const ids = row.id
      this.$alert('您是否确认删除？')
        .then(function () {
          return delCommerceUser(ids)
        })
        .then(() => {
          this.search()
          this.$message.success('删除成功')
        })
        .catch(() => {})
    },
    //add关联用户
    submituser() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if (this.adduser.id == null) {
            adduser(this.adduser).then((response) => {
              this.$message.success('保存成功')
              this.open = false
              this.search()
            })
          } else {
            edituser(this.adduser).then((response) => {
              this.$message.success('保存成功')
              this.open = false
              this.search()
            })
          }
        }
      })
    },
    cancel() {
      this.open = false
    },
    //新增指导单位
    addguidances() {
      this.flag = true
      this.addguidance={
        chamberCommerceId: this.$route.query.id * 1,
        guidanceName: null,
        id: null
      }
    },
    cancelguidance() {
      this.flag = false
    },
    //编辑关联单位
    async editguidance(row) {
      this.flag = true
      const res = await getguidanceinfo(row.id)
      if (res.data.resultCode == 200) {
        this.addguidance = res.data.data
      }
    },
    //删除关联单位
    delguidanced(row) {
      const ids = row.id
      this.$alert('您是否确认删除？')
        .then(function () {
          return delguidance(ids)
        })
        .then(() => {
          this.search()
          this.$message.success('删除成功')
        })
        .catch(() => {})
    },
    //保存指导单位
    submitguidance() {
      if (this.addguidance.id == null) {
        addguidance(this.addguidance).then((response) => {
          this.$message.success('保存成功')
          this.flag = false
          this.search()
        })
      } else {
        editguidance(this.addguidance).then((response) => {
          this.$message.success('保存成功')
          this.flag = false
          this.search()
        })
      }
    },
    //新增关联企业
    companyClick() {
      this.comflag = true
      this.getcompany()
    },
    submitcompany() {
      if (this.addcompany.id == null) {
        addCommerceCompan(this.addcompany).then((response) => {
          this.$message.success('保存成功')
          this.comflag = false
          this.search()
        })
      } else {
        editCommerceCompan(this.addcompany).then((response) => {
          this.$message.success('保存成功')
          this.comflag = false
          this.search()
        })
      }
    },
    //取消
    cancelcompany() {
      this.comflag = false
    },
    //编辑企业
    editcompany(item) {
      this.$router.push({
        name: 'addenterprise',
        query: { id: item.id }
      })
    },
    //删除关联企业
    delcompany(row) {
      const ids = row.id
      this.$alert('您是否确认删除？')
        .then(function () {
          return delcompany(ids)
        })
        .then(() => {
          this.search()
          this.$message.success('删除成功')
        })
        .catch(() => {})
    },
    userinfoClick() {},
    //获取长地址图片
    uploadOneImgShowDian(val) {
      this.adduser.headImgUrl = val
    },
    /**
     * 删除图
     */
    removeImgDian(val) {
      this.adduser.headImgUrl = ''
    },
    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.search()
      // console.log(`每页 ${val} 条`)
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      this.search()
      // console.log(`当前页: ${val}`)
    }
  }
}
</script>
<style lang="less" scoped>
::v-deep .el_upload .el-form-item {
  margin-bottom: 0px;
}
::v-deep .name_form .el-input__suffix {
  position: absolute;
  height: 100%;
  right: -54px;
  top: 0;
  text-align: center;
  color: #c0c4cc;
  transition: all 0.3s;
  pointer-events: none;
}
::v-deep .el-step__title {
  font-size: 14px;
  line-height: 38px;
}

::v-deep .name_form .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 41px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 556px;
}
::v-deep .name_form .el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 496px;
}

.Collections {
  .head_img {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background: #ffffff;

    .img_title {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 10px 5px 10px;

      img {
        width: 68px;
        height: 68px;
        border-radius: 5px;
      }

      .title {
        margin: 0px 0px 0px 10px;

        .merchant_name {
          font-weight: 550;
          font-size: 18px;
          color: #1f2d3d;
          margin: 0px;
          position: relative;
          top: -5px;
        }

        .merchant_type {
          position: relative;
          top: 10px;
          padding: 4px 8px;
          color: #2e7aff;
          background: #e1ecff;
          border-radius: 4px;
          font-size: 13px;
          line-height: 28px;
          text-align: center;
        }
      }
    }
  }
  .switch_tab {
    background: #ffffff;
    margin-top: 20px;
    .store_table {
      padding: 30px 30px;
      width: 100%;
      overflow: hidden;
      height: 48px;
      line-height: 48px;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      background: #ffffff;

      .store_table_item {
        padding: 0px 20px;
        overflow: hidden;
        line-height: 48px;
        box-sizing: border-box;
        color: #1f2d3d;
        cursor: pointer;
        text-align: center;
        font-weight: 400;
        font-size: 20px;
      }

      .active {
        border-bottom: 2px solid #4e93fb;
        color: #4e93fb;
        background-color: #fff;
      }
    }
    .essential_information {
      padding: 30px 40px;
      .general_input {
        width: 845px;
        border: 1px solid #d4d4d4;
        padding: 14px 16px;

        .input_flex {
          display: flex;
          justify-content: space-between;
          padding: 7px 0px;
          span {
            padding-left: 15px;
            font-size: 20px;
            cursor: pointer;
          }
        }
      }
      .img_tips {
        position: relative;
        top: -10px;
        color: #6ba1ff;
      }

      h4 {
        color: #146aff;
      }
      .dialog-footer {
        display: flex;
        justify-content: center;
      }
    }
  }

  .label_management {
    padding: 10px 20px;

    .new_page {
      margin: 20px auto;
      width: 100%;
      text-align: center;
    }

    .input-with {
      width: 100%;
      overflow: hidden;
      padding: 20px 0px;

      .input-with-select {
        width: 400px;
        float: right;
      }

      .seach {
        background-color: #448aff;
        color: #fff;
        border-radius: 0;
        border: 1px solid #448aff;
      }
    }
  }
}
</style>
